import { Accordion } from "@mantine/core";
import React, { useCallback } from "react";
import { ImStack } from "react-icons/im";
import { GoAlert, GoAlertFill, GoCheckCircle, GoQuestion } from "react-icons/go";
import { GetField } from './GetField';

interface PrescriptionAccordionProps {
  document: any;
  fieldFocused: (data:any) => void;
  fieldBlurred: () => void;
}

export const PrescriptionAccordion: React.FC<PrescriptionAccordionProps> = ({
  document,
  fieldFocused,
  fieldBlurred
}) => {

  const prescriptionTax: { [key: string]: string } = {
    'provider_clinic': 'Clinic Name',
    'provider_fax_number': 'Fax Number',
    'provider_phone_number': 'Phone Number',
    'patient_first_name': 'First Name',
    'patient_last_name': 'Last Name',
    'patient_address': 'Address',
    'patient_insurance_id': 'Insurance ID',
    'patient_insurance_provider_name': 'Insurance Provider Name',
    'patient_mrn': 'MRN',
    'patient_dob': 'Date of Birth',
    'patient_sex': 'Sex',
    'patient_home_phone': 'Home Phone',
    'patient_cell_phone': 'Cell Phone',
    'order_date': 'Order Date',
    'diagnoses': 'Diagnoses',
    'diagnosis_codes': 'Diagnosis Codes',
    'provider_name': 'Provider Name',
    'provider_signature_present': 'Provider Signature Present',
    'provider_signature_type': 'Provider Signature Type',
    'provider_signature_date': 'Provider Signature Date',
    'provider_npi': 'Provider NPI',
    'description_of_order': 'Description of Order',
    'refills': 'Refills',
    'quantity': 'Quantity',
    'length_of_need': 'Length of Need',
  }

  
  const renderConfidenceCounts = (sectionKeys: string[]) => {
    const confidenceCounts = sectionKeys
      .reduce((acc, key) => {
        const confidence = document[key]?.confidence;
        if (confidence) {
          acc[confidence] = (acc[confidence] || 0) + 1;
        }
        return acc;
      }, {} as Record<string, number>);

    return (
      <span className="ml-2 text-sm flex items-center">
        {Object.entries(confidenceCounts).map(([confidence, count]) => (
          <span key={confidence} className="mr-2 flex items-center">
            {confidence === 'High' ? (
              <GoCheckCircle className="mr-1 flex-shrink-0" size={16} color="lightgreen" />
            ) : confidence === 'Medium' ? (
              <GoAlert className="mr-1 flex-shrink-0" size={16} color="orange" />
            ) : confidence === 'Low' ? (
              <GoAlertFill className="mr-1 flex-shrink-0" size={16} color="red" />
            ) : (
              <GoQuestion className="mr-1 flex-shrink-0" size={16} color="lightgray" />
            )}
            {count}
          </span>
        ))}
      </span>
    );
  };

  const generateAccordionItem = (
    value: string,
    title: string,
    filterFn: (key: string) => boolean,
    showConfidenceCounts: boolean = false
  ) => (
    <Accordion.Item value={value} key={value}>
      <Accordion.Control icon={<ImStack />}>
        <div className="flex items-center justify-between w-full">
          <span>{title}</span>
          {showConfidenceCounts && renderConfidenceCounts(Object.keys(prescriptionTax).filter(filterFn))}
        </div>
      </Accordion.Control>
      <Accordion.Panel>
        {Object.keys(prescriptionTax)
          .filter(filterFn)
          .map(key => (
            <div key={`${value}-${key}`}>
              <GetField
                label={prescriptionTax[key] || key.replace(`${value.toLowerCase()}_`, '').replace(/_/g, ' ')}
                data={document[key]}
                isHorizontal={true}
                onFocus={fieldFocused}
                onBlur={fieldBlurred}
              />
              {/*getField(
                cnTaxonomy[key] || key.replace(`${value.toLowerCase()}_`, '').replace(/_/g, ' '),
                document[key],
                true
              )*/}
            </div>
          ))
        }
      </Accordion.Panel>
    </Accordion.Item>
  );

  return (
    <Accordion multiple={true} defaultValue={["Patient Information", "Provider Information", "Prescription Information"]}>
      {generateAccordionItem(
      "Patient Information",
      "Patient Information",
      (key) => key.startsWith('patient'),
      true
    )}
    {generateAccordionItem(
      "Provider Information",
      "Provider Information",
      (key) => key.startsWith('provider'),
      true
    )}
    {generateAccordionItem(
      "Prescription Information",
      "Prescription Information",
      (key) => !key.startsWith('provider') && !key.startsWith('patient'),
      true
    )}
    </Accordion>
  );
};